import useQuoteStore from "@/store/quote";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { cms } from "@/lib/apollo/client";
import {
    useGetInstallLocationsQuery,
    useProductQuery,
} from "@/.graphql/cms/graphql";
import { useInformationContext } from "@/hooks/useInformationContext/useInformationContext";

const ReplaceBar = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("translation", {
        keyPrefix: "organisms.header",
    });
    const [replacementReference, , removeReplacementReference] =
        useSessionStorage<string | null>("kozien/replace-item", null);

    const handleCancel = () => {
        removeReplacementReference();
        navigate("/quote");
    };

    const { productByReference } = useQuoteStore();

    const { type: informationType, params: informationParams } =
        useInformationContext();

    const productReference = productByReference(replacementReference || "");
    const { productId, installLocationId } = productReference || {};
    const { data: { Product: product } = {} } = useProductQuery({
        client: cms,
        variables: { productId },
        skip: productId == null,
        onError: (error) => void console.error(error),
    });

    const { data: installLocationsData } = useGetInstallLocationsQuery({
        variables: {
            limit: 999,
        },
    });

    const installLocation =
        installLocationsData?.InstallLocations?.docs?.filter(
            (installLocation) => installLocation?.id === installLocationId,
        )?.[0];

    if (!replacementReference) return null;

    return (
        <Box bgcolor="#fff1cb" py={1.5} zIndex={100} position="relative">
            <Container>
                <Stack
                    direction="row"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Typography
                        variant="body1"
                        display="flex"
                        gap={0.5}
                        flexWrap="wrap"
                    >
                        {t("replaceBar.prefix")}
                        <Typography
                            variant="body1"
                            fontWeight="bold"
                            component="span"
                        >
                            {informationType === "measurement" &&
                            informationParams &&
                            "position" in informationParams
                                ? `${informationParams.position}. `
                                : null}
                            {product?.title}
                        </Typography>
                        {installLocation && (
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                component="span"
                            >
                                {" - "}
                                {installLocation.name}
                            </Typography>
                        )}
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="error.main"
                        sx={{
                            cursor: "pointer",
                            "&:hover": { textDecoration: "underline" },
                        }}
                        onClick={handleCancel}
                    >
                        {t("replaceBar.cancel")}
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
};

export default ReplaceBar;
