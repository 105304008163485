import { ApolloClient, InMemoryCache } from "@apollo/client";
import possibleTypes from "@graphql/backend/possibleTypes";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";

const uploadLink = createUploadLink({
    uri: import.meta.env.VITE_BACKEND_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Authorization: `ApiKey ${import.meta.env.VITE_BACKEND_API_KEY}`,
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache({
        possibleTypes: possibleTypes.possibleTypes,
    }),
    connectToDevTools: Boolean(import.meta.env.VITE_APOLLO_DEVTOOLS),
});

export default client;
