import css from "./InformationBar.module.scss";

type InformationBarProps = {
    children: React.ReactNode;
};

export function InformationBar({ children }: InformationBarProps) {
    return (
        <div className={css.redirectBar} data-testid="redirect-bar">
            {children}
        </div>
    );
}
