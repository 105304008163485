import type { DrawerProps } from "@mui/material";
import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

interface StyledDrawerProps extends DrawerProps {
    menuheight: number;
    siteUrlVisible: boolean;
}

export const StyledDrawer = styled(Drawer)<StyledDrawerProps>(
    ({ menuheight, siteUrlVisible }) => ({
        "& .MuiDrawer-paper": {
            marginTop: siteUrlVisible ? 37 : 0,
            paddingTop: menuheight + 20,
            paddingLeft: 20,
            paddingRight: 20,
            width: "100%",
            boxShadow: "none",
        },
    }),
);
